import { IRouterWithID } from '~/core/@types/global'
import {
  ACTIONS_PERMISSIONS,
  canAccessFeature,
  canActionFeature,
  canPermissionFeature,
  DROPDOWN_PERMISSIONS,
  PERMISSIONS_LIST
} from '~/core/utilities/feature-permission'
import useBoundStore from '~/lib/store'

const usePermissionCandidate = ({
  applicantId
}: {
  applicantId?: IRouterWithID
}) => {
  const { currentRole, permissionSetting } = useBoundStore()

  const canAccessPage = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      applicantId
        ? PERMISSIONS_LIST.job_management.objects.applicant.keyModuleObject
        : PERMISSIONS_LIST.job_management.objects.profile.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessProfile = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.profile.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessApplicant = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.applicant.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessFullPermissionApplicant = canPermissionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.applicant.keyModuleObject
    ],
    permissionAction: DROPDOWN_PERMISSIONS.full
  })

  const canAccessNote = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      applicantId
        ? PERMISSIONS_LIST.job_management.objects.applicant_note.keyModuleObject
        : PERMISSIONS_LIST.job_management.objects.profile_note.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAccessTask = canAccessFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      applicantId
        ? PERMISSIONS_LIST.job_management.objects.applicant_task.keyModuleObject
        : PERMISSIONS_LIST.job_management.objects.profile_task.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  })

  const canAction = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      applicantId
        ? PERMISSIONS_LIST.job_management.objects.applicant.keyModuleObject
        : PERMISSIONS_LIST.job_management.objects.profile.keyModuleObject
    ]
  })

  const actionProfile = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.profile.keyModuleObject
    ]
  })

  const actionApplicant = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.applicant.keyModuleObject
    ]
  })

  const actionApplicantNote = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.applicant_note.keyModuleObject
    ]
  })

  const actionProfileNote = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.profile_note.keyModuleObject
    ]
  })

  const actionApplicantTask = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.applicant_task.keyModuleObject
    ]
  })

  const actionProfileTask = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.profile_task.keyModuleObject
    ]
  })

  const actionProfileApplicantTask = canActionFeature({
    currentRole,
    permissionSetting,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.profile_task.keyModuleObject,
      PERMISSIONS_LIST.job_management.objects.applicant_task.keyModuleObject
    ]
  })

  return {
    canAccessPage,
    canAccessProfile,
    canAccessApplicant,
    canAccessFullPermissionApplicant,
    canAccessNote,
    canAccessTask,
    canAction,
    actionProfile,
    actionApplicant,
    actionApplicantNote,
    actionProfileNote,
    actionApplicantTask,
    actionProfileTask,
    actionProfileApplicantTask
  }
}

export default usePermissionCandidate
