import useBoundStore from '../store'

export const useUserCheckKindOf = () => {
  const { user } = useBoundStore()

  const userIsAsClient = () => {
    if (user?.userTenants?.[0]?.userKind === 'client') {
      return true
    }
    return false
  }
  return {
    userIsAsClient
  }
}
