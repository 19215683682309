'use client'

import { useCallback, useState } from 'react'
import { Accept, FileRejection, useDropzone } from 'react-dropzone'
import { AlertCircleFill, CloudUploadOutlined } from '~/core/ui/FillIcons'
import { IconButton } from '~/core/ui/IconButton'
import IconWrapper from '~/core/ui/IconWrapper'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from './utils'
import { convertFileSizeToBytes } from '~/lib/schema'

interface UploadFileDragAndDropProps {
  label?: string
  className?: string
  classNameWrapper?: string
  textButtonClassName?: string
  dragNDropHelperText?: string
  controlHelperText?: string
  accept?: Accept // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  maximumFiles?: number
  maximumSizeFile?: string
  files?: Array<File>
  onChange: (files: Array<File> | undefined) => void
  onUploadFiles?: ({ files }: { files: Array<File> }) => void
  destructive?: boolean
  showRejectFiles?: boolean
  isDisabled?: boolean
  wrapperID?: string
  hideDragAndDropUpload?: boolean
  urlFiles?: { name: string; url: string }[]
  configText?: {
    [string: string]: string
  }
  hideDeleteFileButton?: boolean
  showTooltip?: boolean
}

const UploadFileDragAndDrop = (props: UploadFileDragAndDropProps) => {
  const {
    label,
    className = '',
    classNameWrapper = '',
    textButtonClassName = '',
    configText,
    dragNDropHelperText,
    controlHelperText,
    accept = {},
    maximumFiles = 10,
    maximumSizeFile = '10MB',
    files = [],
    onChange,
    destructive = false,
    showRejectFiles = true,
    isDisabled = false,
    wrapperID,
    hideDragAndDropUpload = false,
    hideDeleteFileButton = false,
    urlFiles,
    showTooltip = true
  } = props
  const [rejectFiles, setRejectFiles] = useState<Array<FileRejection>>([])
  const onClickDeleteFile = useCallback(
    (indexFile: number) => {
      const data = files.filter((_, index) => index !== indexFile)
      onChange(data?.length === 0 ? [] : data)
    },
    [files, onChange]
  )

  const onDropAccepted = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      const mergeFiles = [...acceptedFiles, ...files].slice(0, maximumFiles)
      onChange(mergeFiles)
      setRejectFiles([])
    },
    [onChange, files, maximumFiles]
  )

  const onDropRejected = useCallback(
    (fileRejections: Array<FileRejection>) => {
      const mappingFileReject = fileRejections.map((item) => item.file)
      const mergeFiles = [...mappingFileReject, ...files].slice(0, maximumFiles)
      onChange(mergeFiles)
      showRejectFiles && setRejectFiles(fileRejections)
    },
    [onChange]
  )

  const { getRootProps, getInputProps } = useDropzone({
    // maxFiles: maximumFiles,
    maxSize: convertFileSizeToBytes({ size: maximumSizeFile }),
    accept,
    onDropAccepted,
    onDropRejected,
    disabled: isDisabled
  })

  const rendered = () => (
    <div
      id={wrapperID}
      className={cn(
        'rounded border-2 border-dashed focus:border-2 focus:border-primary-300 focus:dark:border-primary-700',
        destructive ? 'border-red-500' : 'border-gray-300 dark:border-gray-600',
        'cursor-pointer px-[22px] pb-[26px] pt-[22px] text-center focus:dark:bg-gray-800',
        classNameWrapper,
        isDisabled ? 'border-gray-300 bg-gray-50' : ''
      )}
      {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="mb-1">
        <CloudUploadOutlined
          className={cn(
            'm-auto',
            isDisabled
              ? 'stroke-gray-400 dark:stroke-gray-400'
              : 'stroke-gray-500 dark:stroke-gray-400'
          )}
        />
      </div>
      <div className="mb-1">
        <span
          className={cn(
            'mr-1 text-sm font-medium',
            isDisabled
              ? 'text-primary-200 dark:text-primary-400'
              : `text-primary-400 dark:text-primary-400 ${textButtonClassName}`,

          )}>
          {configText?.clickToUpload || 'Click to upload'}
        </span>
        <span
          className={cn(
            'text-sm font-medium',
            isDisabled
              ? 'text-gray-400 dark:text-gray-300'
              : 'text-gray-700 dark:text-gray-300'
          )}>
          {configText?.orDragAndDrop || 'or drag and drop'}
        </span>
      </div>
      {dragNDropHelperText && (
        <p
          className={cn(
            'text-center text-xs',
            isDisabled
              ? 'text-gray-400 dark:text-gray-400'
              : 'text-gray-600 dark:text-gray-400'
          )}>
          {dragNDropHelperText}
        </p>
      )}
    </div>
  )
  return (
    <div className={cn('w-full', className)}>
      {label && (
        <p className="mb-1 text-sm text-gray-700 dark:text-gray-400">{label}</p>
      )}
      {!hideDragAndDropUpload && (
        <>
          {isDisabled && showTooltip ? (
            <Tooltip content={`Maximum ${maximumFiles} files`}>
              {rendered()}
            </Tooltip>
          ) : (
            rendered()
          )}
          {controlHelperText && (
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
              {controlHelperText}
            </p>
          )}
        </>
      )}
      {files.length || rejectFiles.length ? (
        <div className="mt-3">
          {files.map((file, index) => (
            <div
              key={`accept-file-${index}`}
              className="mb-2 flex justify-between rounded bg-gray-50 px-3 py-2 last:mb-0 dark:bg-gray-800">
              <div className="flex items-center pt-0.5">
                <div className="pt-0.5">
                  <IconWrapper
                    name="Paperclip"
                    size={16}
                    className="mr-2 text-gray-400"
                  />
                </div>
                {urlFiles && urlFiles.length > 0 ? (
                  <a
                    href={urlFiles.find((fi) => fi.name === file.name)?.url}
                    target="_blank"
                    className={cn(
                      'line-clamp-1 whitespace-break-spaces break-all text-sm font-medium text-gray-700	dark:text-gray-300	',
                      urlFiles.find((fi) => fi.name === file.name)?.url
                        ? 'hover:underline'
                        : ''
                    )}>
                    {file.name}
                  </a>
                ) : (
                  <p className="line-clamp-1 whitespace-break-spaces break-all text-sm font-medium text-gray-700	dark:text-gray-300	">
                    {file.name}
                  </p>
                )}
              </div>
              <div className="flex">
                <div {...getRootProps()}>
                  <Tooltip content={configText?.uploadANewFile || "Upload a new file"}>
                    <IconButton
                      iconMenus="Upload"
                      size="xs"
                      type="secondary"
                    />
                    <input {...getInputProps()} />
                  </Tooltip>
                </div>
                {!hideDeleteFileButton && <Tooltip content={configText?.delete || "Delete"}>
                  <IconButton
                    iconMenus="Trash2"
                     className="ml-2"
                    onClick={() => onClickDeleteFile(index)}
                    size="xs"
                    type="secondary-destructive"
                  />
                </Tooltip>}
              </div>
            </div>
          ))}
          {maximumFiles > 1 &&
            rejectFiles.map(({ file, errors }, index) => {
              return (
                <div
                  key={`file-rejection-${index}`}
                  className="mb-2 flex justify-between rounded bg-gray-50 px-3 py-2 last:mb-0 dark:bg-gray-800">
                  <div className="flex">
                    <div className="pt-0.5">
                      <AlertCircleFill className="mr-2 fill-red-500" />
                    </div>
                    <div>
                      <p className="line-clamp-1 whitespace-break-spaces break-all text-sm font-medium text-gray-700 dark:text-gray-300">
                        {file.name}
                      </p>

                      <p className="text-xs text-red-500 dark:text-red-500">
                        {errors.map((error, index) => {
                          if (error.code === 'file-invalid-type') {
                            return (
                              <div key={index}>
                                {
                                  'Wrong file format. Allowed PDF, DOCX, or DOC.'
                                }
                              </div>
                            )
                          }
                          if (error.code === 'file-too-large') {
                            return (
                              <div
                                key={
                                  index
                                }>{`File size is too large. Max size is ${maximumSizeFile}.`}</div>
                            )
                          }
                          return
                        })}
                      </p>
                    </div>
                  </div>
                  <Tooltip content={configText?.tryAgain ||  "Try again"}>
                    <IconButton
                      iconMenus="RotateCcw"
                      onClick={() => {}}
                      size="xs"
                      type="secondary"
                    />
                  </Tooltip>
                </div>
              )
            })}
        </div>
      ) : null}
    </div>
  )
}

UploadFileDragAndDrop.displayName = 'UploadFileDragAndDrop'

export { UploadFileDragAndDrop }
export type { UploadFileDragAndDropProps }
