import { Trans, useTranslation } from 'react-i18next'
import useStaticData from 'src/hooks/data/use-static-data'
import HTMLDisplay from '~/components/HTMLDisplay'
import { IRouterWithID } from '~/core/@types/global'
import { openAlert } from '~/core/ui/AlertDialog'
import { Avatar } from '~/core/ui/Avatar'
import { IconButton } from '~/core/ui/IconButton'
import { TypographyText } from '~/core/ui/Text'
import { EducationsType } from '~/lib/features/candidates/types'
import {
  getEducationFieldFormatDate,
  getEducationTime
} from '~/lib/features/candidates/utilities/format'
import { getFormatDegreeEducation } from '~/lib/features/settings/profiles/edit/utilities'
import DisableControlByPermission from '../../DisableControlByPermission'

const EducationView = ({
  source = [],
  onClick,
  onDelete,
  isLoadingUpdateProfile = false,
  isViewOnly = false,
  applicantId
}: {
  source?: Array<EducationsType>
  onClick?: (item: EducationsType) => void
  onDelete?: (item: EducationsType) => void
  isLoadingUpdateProfile?: boolean
  isViewOnly?: boolean
  applicantId?: IRouterWithID
}) => {
  const { t, i18n } = useTranslation()
  const degrees = useStaticData({
    keyName: 'degrees',
    locale: i18n.language
  })
  const listDegrees = degrees.map(
    (item: { name: string; description: string }) => {
      return {
        value: item.name,
        supportingObj: {
          name: item.description
        }
      }
    }
  )

  return (
    <div className="space-y-5">
      {source.map((item, index) => (
        <div className="relative flex" key={`item-${index}`}>
          <div className="mr-4 pt-1.5">
            <Avatar defaultAvatar={false} size="2xl" shape="rounded" />
          </div>
          <div className="flex-1">
            <div className="group flex justify-between">
              <div>
                <TypographyText className="mb-0.5 text-sm font-medium text-gray-900">
                  {item.degreeSubject}
                </TypographyText>

                <div className="mb-0.5 flex items-center">
                  {getFormatDegreeEducation({
                    degree: item.degree,
                    degreeSubject: undefined,
                    listDegrees
                  }) ? (
                    <>
                      <TypographyText className="text-sm text-gray-900">
                        {getFormatDegreeEducation({
                          degree: item.degree,
                          degreeSubject: undefined,
                          listDegrees
                        })}
                      </TypographyText>
                      <span className="mx-2.5 h-0.5 w-0.5 rounded bg-gray-400" />
                    </>
                  ) : null}
                  <TypographyText className="text-sm text-gray-900">
                    {item.schoolName}
                  </TypographyText>
                </div>

                {getEducationFieldFormatDate({
                  type: 'from',
                  attributes: item
                }) && (
                  <div className="mt-2-px text-sm text-gray-600">
                    {getEducationFieldFormatDate({
                      type: 'from',
                      attributes: item,
                      keys: {
                        undefined: t('label:undefined')
                      }
                    })}
                    {` - ${getEducationFieldFormatDate({
                      type: 'to',
                      attributes: item,
                      keys: {
                        undefined: t('label:undefined')
                      }
                    })}`}
                    {` `}(
                    {getEducationTime({
                      startDate: item.from,
                      endDate: item.to,
                      t
                    })}
                    )
                  </div>
                )}
              </div>
              {!isViewOnly && (
                <DisableControlByPermission applicantId={applicantId}>
                  <div className="absolute right-0 top-0 hidden items-center space-x-1 rounded border border-solid border-gray-100 bg-white p-0.5 shadow-actions group-hover:flex">
                    <IconButton
                      size="xs"
                      isDisabled={isLoadingUpdateProfile}
                      type="secondary"
                      iconMenus="Edit3"
                      onClick={() => onClick && onClick(item)}
                    />
                    <IconButton
                      size="xs"
                      isLoading={isLoadingUpdateProfile}
                      isDisabled={isLoadingUpdateProfile}
                      type="secondary-destructive"
                      iconMenus="Trash2"
                      className="border-none"
                      onClick={() => {
                        openAlert({
                          isPreventAutoFocusDialog: false,
                          className: 'w-[480px]',
                          title: `${t(
                            'candidates:tabs:candidateOverview:education:deleteTitle'
                          )}`,
                          description: (
                            <Trans
                              i18nKey="candidates:tabs:candidateOverview:education:deleteDescription"
                              values={{
                                title: item.degreeSubject
                              }}>
                              <span className="font-medium text-gray-900" />
                            </Trans>
                          ),
                          actions: [
                            {
                              label: `${t('button:cancel')}`,
                              type: 'secondary',
                              size: 'sm'
                            },
                            {
                              isCallAPI: true,
                              label: `${t('button:remove')}`,
                              type: 'destructive',
                              size: 'sm',
                              onClick: async () => {
                                if (onDelete) {
                                  await onDelete(item)
                                }
                              }
                            }
                          ]
                        })
                      }}
                    />
                  </div>
                </DisableControlByPermission>
              )}
            </div>

            <div className="mb-2">
              <HTMLDisplay
                content={item?.description}
                className="text-sm text-gray-900"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default EducationView
