import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { pushStateBrowser } from '~/core/utilities/is-browser'

const useBrowserTab = ({
  defaultValue,
  queryKeyName,
  pushState,
  excludeQueryKeysName = []
}: {
  defaultValue: string
  queryKeyName: string
  pushState?: boolean
  excludeQueryKeysName?: Array<string>
}) => {
  const router = useRouter()
  const [valuePushState, setValuePushState] = useState(
    window?.history?.state || {}
  )
  return useMemo(
    () => ({
      value: pushState
        ? valuePushState[queryKeyName] || defaultValue
        : (router.query[queryKeyName] as string) || defaultValue,
      onValueChange: (tabKey: string) => {
        if (pushState) {
          const queryReduce = Object.keys(window.history.state || {}).reduce(
            (result, key) =>
              !excludeQueryKeysName.includes(key)
                ? { ...result, [key]: window.history.state?.[key] }
                : result,
            {}
          )
          const query = { ...queryReduce, [queryKeyName]: tabKey }
          const objString = '?' + new URLSearchParams(query).toString()

          setValuePushState(query)
          pushStateBrowser({
            state: query,
            unused: '',
            url: `${window.location.pathname}${objString}`
          })
        } else {
          const query = Object.keys(router.query || {}).reduce(
            (result, key) =>
              !excludeQueryKeysName.includes(key)
                ? { ...result, [key]: router.query?.[key] }
                : result,
            {}
          )

          router.replace(
            {
              pathname: router.pathname,
              query: { ...router.query, [queryKeyName]: tabKey }
            },
            {
              pathname: router.pathname,
              query: { ...query, [queryKeyName]: tabKey }
            },
            { shallow: true }
          )
        }
      }
    }),
    [router, valuePushState]
  )
}
export default useBrowserTab
