const MutationDeleteProfile = `
  mutation ($ids: [Int!], $id: Int) {
    profilesDelete(
      input: {
        ids: $ids,
        id: $id,
      }
    ) {
      success
    }
  }
`

export default MutationDeleteProfile
