import { PropsWithChildren, ReactElement } from 'react'
import { IRouterWithID } from '~/core/@types/global'
import usePermissionCandidate from '~/lib/features/permissions/hooks/use-permission-candidate'

const DisableControlByPermission = (
  props: PropsWithChildren<{
    text?: ReactElement
    disabled?: boolean
    applicantId?: IRouterWithID
  }>
) => {
  const { canAccessPage, canAction } = usePermissionCandidate({
    applicantId: props.applicantId
  })
  const isShowDisabled = !(canAccessPage && canAction.update)

  return isShowDisabled || props?.disabled ? (
    <>{props.text}</>
  ) : (
    <>{props.children}</>
  )
}
export default DisableControlByPermission
