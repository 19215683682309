/**
 * @name isBrowser
 * @description Check if the code is running in the browser. Useful to avoid
 * SSR errors.
 */
export function isBrowser() {
  return typeof window !== 'undefined'
}

export function pushStateBrowser({
  state,
  unused,
  url
}: {
  state?: object
  unused: string
  url: string
}) {
  if (!isBrowser() || !window.history) return ''

  return window.history.pushState(state, unused, url)
}
