import { Trans, useTranslation } from 'react-i18next'
import HTMLDisplay from '~/components/HTMLDisplay'
import { IRouterWithID } from '~/core/@types/global'
import { openAlert } from '~/core/ui/AlertDialog'
import { Avatar } from '~/core/ui/Avatar'
import { IconButton } from '~/core/ui/IconButton'
import { TypographyText } from '~/core/ui/Text'
import { WorkExperiencesType } from '~/lib/features/candidates/types'
import {
  getWorkExperienceTime,
  getWorkExpFieldFormatDate
} from '~/lib/features/candidates/utilities/format'
import DisableControlByPermission from '../../DisableControlByPermission'

const WorkExperiencesView = ({
  source = [],
  onClick,
  onDelete,
  isLoadingUpdateProfile = false,
  isViewOnly = false,
  applicantId
}: {
  source?: Array<WorkExperiencesType>
  onClick?: (item: WorkExperiencesType) => void
  onDelete?: (item: WorkExperiencesType) => void
  isLoadingUpdateProfile?: boolean
  isViewOnly?: boolean
  applicantId?: IRouterWithID
}) => {
  const { t } = useTranslation()
  return (
    <div className="space-y-5">
      {source.map((item, index) => (
        <div className="relative flex" key={`item-${index}`}>
          <div className="mr-4 pt-1.5">
            <Avatar defaultAvatar={false} size="2xl" shape="rounded" />
          </div>
          <div className="flex-1">
            <div className="group flex justify-between ">
              <div>
                <TypographyText className="mb-0.5 text-base font-medium text-gray-900">
                  {item.title}
                </TypographyText>

                <div className="mb-0.5 flex items-center">
                  <TypographyText className="text-sm text-gray-900">
                    {item.company}
                  </TypographyText>
                  {item.location && (
                    <>
                      <span className="mx-2.5 h-0.5 w-0.5 rounded bg-gray-400" />
                      <TypographyText className="text-sm text-gray-900">
                        {item.location}
                      </TypographyText>
                    </>
                  )}
                </div>

                {
                  <div className="mt-2-px text-sm text-gray-600">
                    {getWorkExpFieldFormatDate({
                      type: 'from',
                      attributes: item
                    }) && (
                      <>
                        {getWorkExpFieldFormatDate({
                          type: 'from',
                          attributes: item,
                          keys: {
                            undefined: t('label:undefined')
                          }
                        })}
                        {item?.currentWorking ? (
                          <> - {t('label:present')}</>
                        ) : (
                          <>
                            {` - ${getWorkExpFieldFormatDate({
                              type: 'to',
                              attributes: item,
                              keys: {
                                undefined: t('label:undefined')
                              }
                            })}`}
                          </>
                        )}
                        {` `}({getWorkExperienceTime(item, t)})
                      </>
                    )}
                  </div>
                }
              </div>
              {!isViewOnly && (
                <DisableControlByPermission applicantId={applicantId}>
                  <div className="absolute right-0 top-0 hidden items-center space-x-1 rounded border border-solid border-gray-100 bg-white p-0.5 shadow-actions group-hover:flex">
                    <IconButton
                      size="xs"
                      isDisabled={isLoadingUpdateProfile}
                      type="secondary"
                      iconMenus="Edit3"
                      className="border-none"
                      onClick={() => onClick && onClick(item)}
                    />
                    <IconButton
                      size="xs"
                      isLoading={isLoadingUpdateProfile}
                      isDisabled={isLoadingUpdateProfile}
                      type="secondary-destructive"
                      iconMenus="Trash2"
                      className="border-none"
                      onClick={() => {
                        openAlert({
                          isPreventAutoFocusDialog: false,
                          className: 'w-[480px]',
                          title: `${t(
                            'candidates:tabs:candidateOverview:workExperiences:deleteTitle'
                          )}`,

                          description: (
                            <Trans
                              i18nKey="candidates:tabs:candidateOverview:workExperiences:deleteDescription"
                              values={{
                                title: item.title
                              }}>
                              <span className="font-medium text-gray-900" />
                            </Trans>
                          ),
                          actions: [
                            {
                              label: `${t('button:cancel')}`,
                              type: 'secondary',
                              size: 'sm'
                            },
                            {
                              isCallAPI: true,
                              label: `${t('button:remove')}`,
                              type: 'destructive',
                              size: 'sm',
                              onClick: async () => {
                                if (onDelete) {
                                  await onDelete(item)
                                }
                              }
                            }
                          ]
                        })
                      }}
                    />
                  </div>
                </DisableControlByPermission>
              )}
            </div>

            <div className="mb-2">
              <HTMLDisplay
                content={item?.description}
                className="text-sm text-gray-900"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default WorkExperiencesView
